import React from 'react';
import styled, { css } from 'styled-components';
import DynamicIcon from '../DynamicIcon';
import { useMediaQuery } from '~/hooks/useMediaQuery';

interface BtnSubNivelProps {
  text: string;
  onClick: () => void;
  lib_icon: string | null;
  des_icon: string | null;
}

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.175rem;
  position: relative;
  width: 11.875rem;
  max-width: 11.875rem;
  text-align: left;
  margin-left: -0.625rem;

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.span<{
  isLong: boolean;
}>`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isLong }) =>
    isLong &&
    css`
      width: 100%;
    `}
`;

export const ButtonSubNivel: React.FC<BtnSubNivelProps> = ({
  text,
  onClick,
  lib_icon = '',
  des_icon = '',
}) => {
  const changeText = useMediaQuery({ minWidth: 1500 });

  const maxLength = 21;
  const isLong = text.length > maxLength;
  const truncatedText = isLong ? `${text.substring(0, maxLength)}...` : text;

  return (
    <StyledButton
      onClick={onClick}
      title={!changeText && isLong ? text : undefined}
    >
      <DynamicIcon
        library={lib_icon ?? ''}
        iconName={des_icon ?? ''}
        color="white"
        size={12}
      />
      <ButtonText isLong={isLong}>
        {changeText ? text : truncatedText}
      </ButtonText>
    </StyledButton>
  );
};
