import React, { useEffect, useState } from 'react';

interface DynamicIconProps {
  library: string;
  iconName: string;
  size?: number;
  color?: string;
}

const DynamicIcon: React.FC<DynamicIconProps> = ({
  library,
  iconName,
  size = 24,
  color = 'black',
}) => {
  const [IconComponent, setIconComponent] = useState<React.ElementType | null>(
    null,
  );

  useEffect(() => {
    const loadIcon = async () => {
      try {
        const module = await import(`react-icons/${library.toLowerCase()}`);
        const Icon = module[iconName];

        if (Icon) {
          setIconComponent(() => Icon);
        } else {
          console.log(
            `Ícone "${iconName}" não encontrado na biblioteca "${library}".`,
          );
          setIconComponent(null);
        }
      } catch (error) {
        console.log(
          `Falha ao carregar a biblioteca de ícones "${library}".`,
          error,
        );
        setIconComponent(null);
      }
    };

    loadIcon();
  }, [library, iconName]);

  if (!IconComponent) return null;

  return <IconComponent size={size} color={color} />;
};

export default DynamicIcon;
